import React from 'react';

export const Switch = ({ checked, onCheckedChange }) => {
  return (
    <button
      className={`w-14 h-8 flex items-center rounded-full p-1 ${
        checked ? 'bg-blue-500 justify-end' : 'bg-gray-300 justify-start'
      }`}
      onClick={() => onCheckedChange(!checked)}
    >
      <div className="w-6 h-6 bg-white rounded-full shadow-md" />
    </button>
  );
};

export default Switch;
