import React, { useState } from 'react';

export const Accordion = ({ children, type, collapsible, className }) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export const AccordionItem = ({ children, value, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { isOpen, setIsOpen });
        }
        return child;
      })}
    </div>
  );
};

export const AccordionTrigger = ({ children, className, isOpen, setIsOpen }) => {
  return (
    <button
      className={`w-full text-left ${className}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      {children}
    </button>
  );
};

export const AccordionContent = ({ children, className, isOpen }) => {
  if (!isOpen) return null;
  return (
    <div className={className}>
      {children}
    </div>
  );
};