import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';

// Placeholder components for other pages
const Terms = () => <div>Terms of Service Page</div>;
const Privacy = () => <div>Privacy Policy Page</div>;
const Contact = () => <div>Contact Us Page</div>;

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;