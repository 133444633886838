import React from 'react';
import ReactDOM from 'react-dom/client'; // Import `react-dom/client` for `createRoot`
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/index.css';
import App from './App'; // We'll create this component

// Create root using `createRoot` for React 18+
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
