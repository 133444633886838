// LandingPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./components/ui/accordion";
import { Menu, Book, Flower, Users, CheckCircle, ChevronDown, ChevronLeft, ChevronRight, BookOpen, Wand2, Headphones, Share2, Leaf, Sprout, Play, Heart } from 'lucide-react';
import Slider from "react-slick";
import { motion, useAnimation, useInView } from 'framer-motion'; // Removed unused imports: color, delay
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles/navbar.css';
import './styles/App.css';
import './styles/index.css';
import axios from 'axios';
import { Switch } from "./components/ui/Switch";
import AnimatedUnderline from './components/AnimatedUnderline';

const LandingPage = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [isYearly, setIsYearly] = useState(false);

    const sliderRef = useRef(null);
    const controls = useAnimation();
    const benefitsRef = useRef(null);
    const isInView = useInView(benefitsRef, { once: true });

    useEffect(() => {
      if (isInView) {
        controls.start('visible');
      }
    }, [isInView, controls]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              variableWidth: false,
            }
          }
        ],
        appendDots: dots => (
          <div style={{ bottom: "-30px" }}>
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
    };

    const goToPrev = () => {
      sliderRef.current.slickPrev();
    };

    const goToNext = () => {
      sliderRef.current.slickNext();
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');

      const LIST_ID = process.env.REACT_APP_BREVO_LIST_ID;
      const API_KEY = process.env.REACT_APP_BREVO_API_KEY;

      console.log('Environment Variables:', {
        LIST_ID,
        API_KEY_EXISTS: !!API_KEY,
        NODE_ENV: process.env.NODE_ENV
      });

      try {
        await axios.post(
          'https://api.brevo.com/v3/contacts',
          {
            email: email,
            attributes: { FIRSTNAME: name },
            listIds: [parseInt(LIST_ID)],
            updateEnabled: true
          },
          {
            headers: {
              'api-key': API_KEY,
              'Content-Type': 'application/json'
            }
          }
        );

        setMessage('Thank you for joining our waitlist!');
        setEmail('');
        setName('');
      } catch (error) {
        console.error('Error subscribing:', error.response ? error.response.data : error);
        setMessage('An error occurred. Please try again later.');
      }
    };

    const pricingPlans = [
      {
        plan: 'Basic',
        monthlyPrice: 4.99,
        yearlyPrice: 49.99,
        features: [
          '5 Stories per Month',
          '3 Regenerations per Story',
          '5 Saved Characters',
          { text: 'No Audio Reading', negative: true }
        ]
      },
      {
        plan: 'Premium',
        monthlyPrice: 9.99,
        yearlyPrice: 99.99,
        features: [
          '30 Stories per Month',
          '10 Regenerations Per Story',
          '10 Saved Characters',
          'Basic Audio Reading'
        ]
      },
      {
        plan: 'Premium Plus',
        monthlyPrice: 19.99,
        yearlyPrice: 199.99,
        features: [
          'Unlimited Story Generations',
          'Unlimited Regenerations Per Story',
          'Unlimited Saved Characters',
          'Enhanced Audio Reading'
        ]
      }
    ];

    const calculateYearlyDiscount = (monthlyPrice, yearlyPrice) => {
      const yearlyTotal = monthlyPrice * 12;
      const discount = ((yearlyTotal - yearlyPrice) / yearlyTotal) * 100;
      return Math.round(discount);
    };

    const featureVariants = {
      hidden: { opacity: 0, y: 50 },
      visible: (i) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: i * 0.2,
          duration: 0.4,
          ease: 'easeInOut',
        },
      }),
    };

    const Divider = () => (
      <div className="my-12 border-t border-gray-300"></div>
    );

    return (
      <div className="min-h-screen flex flex-col font-sans">
        {/* Navbar */}
        <nav className="bg-background shadow-sm sticky top-0 z-50 border-b border-gray-300">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <div className="flex-1 flex items-center">
                <img 
                  src="/images/favicon.ico" 
                  alt="Make It Mindful Logo" 
                  className="h-8 w-8 mr-2"
                />
                <div className="flex-1 text-center sm:text-left">
                  <Link to="/" className="text-2xl font-bold text-primary hover:text-primary-hover transition-colors duration-300">
                    Make It Mindful
                  </Link>
                </div>
              </div>
              <div className="hidden sm:flex sm:items-center sm:space-x-8">
                <a href="#features" className="text-text hover:text-primary hover:underline text-sm font-medium">Features</a>
                <a href="#how-it-works" className="text-text hover:text-primary hover:underline text-sm font-medium">How it Works</a>
                <a href="#benefits" className="text-text hover:text-primary hover:underline text-sm font-medium">Benefits</a>
                <a href="#pricing" className="text-text hover:text-primary hover:underline text-sm font-medium">Pricing</a>
                <a href="#faq" className="text-text hover:text-primary hover:underline text-sm font-medium">FAQs</a>
                <Button 
                  className="waitlist-button bg-primary hover:bg-primary-hover text-white rounded-full px-4 py-2 text-sm transition-colors duration-300 shadow-md"
                  onClick={() => setIsModalOpen(true)}
                >
                  Join Waitlist
                </Button>
              </div>
              <div className="sm:hidden">
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                >
                  <span className="sr-only">Open main menu</span>
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          {isMenuOpen && (
            <div className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                <a href="#features" className="text-black hover:text-primary hover:underline block px-3 py-2 text-base font-medium text-center">Features</a>
                <a href="#how-it-works" className="text-black hover:text-primary hover:underline block px-3 py-2 text-base font-medium text-center">How it Works</a>
                <a href="#benefits" className="text-black hover:text-primary hover:underline block px-3 py-2 text-base font-medium text-center">Benefits</a>
                <a href="#faq" className="text-black hover:text-primary hover:underline block px-3 py-2 text-base font-medium text-center">FAQs</a>
                <a href="#pricing" className="text-black hover:text-primary hover:underline block px-3 py-2 text-base font-medium text-center">Pricing</a>
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="mt-3 space-y-1">
                  <Button 
                    className="w-full bg-primary hover:bg-primary-hover text-white rounded-full px-6 py-2 text-base transition-colors duration-300"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Join Waitlist
                  </Button>
                </div>
              </div>
            </div>
          )}
        </nav>
  
        {/* Video Modal */}
        {isVideoModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4" onClick={() => setIsVideoModalOpen(false)}>
            <div className="bg-white rounded-lg w-full max-w-4xl mx-auto relative" onClick={(e) => e.stopPropagation()}>
              <button 
                onClick={() => setIsVideoModalOpen(false)} 
                className="absolute top-4 right-4 text-[#a6a6a6] hover:text-[#4d4d4d]"
                aria-label="Close video modal"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <div className="p-6">
                <h2 className="text-3xl font-bold mb-6 text-black">How It Works</h2>
                <div className="aspect-w-16 aspect-h-9">
                  <iframe 
                    className="w-full h-[450px]"
                    src="https://www.youtube.com/embed/your-video-id" 
                    title="Product Demo" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
  
        {/* Main content wrapper */}
        <main className="flex-grow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
            {/* Hero Section */}
            <section className="bg-white py-12 md:py-16 my-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col md:flex-row items-center justify-between">
                  <div className="md:w-1/2 text-left mb-8 md:mb-0 pr-0 md:pr-8">
                    <div className="relative">
                      <h1 className="text-black text-3xl md:text-4xl lg:text-5xl font-extrabold leading-tight">
                        Personalized Stories for Emotional{' '}
                        <AnimatedUnderline
                          text="Growth"
                          color="#6ca0dc" // Explicitly set the color to match your primary blue
                          fontSize="inherit"
                          className="font-bold"
                        />
                      </h1>
                    </div>
                    <p className="text-text-muted text-lg md:text-xl mt-6">
                      Engage your child's imagination with AI generated stories that foster mindfulness and emotional intelligence.
                    </p>
                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-center mt-8">
                      <Button 
                        className="waitlist-button bg-primary hover:bg-primary-hover text-white rounded-full px-6 py-3 text-base transition-colors duration-300 shadow-md w-full sm:w-auto"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Join Waitlist
                      </Button>
                      <Button 
                        className="watch-video-button bg-gray-300 text-gray-500 border border-gray-400 rounded-full px-6 py-3 text-base shadow-md flex items-center justify-center w-full sm:w-auto cursor-not-allowed"
                        disabled
                      >
                        <Play className="mr-2 h-5 w-5" /> Watch It Work
                      </Button>
                    </div>
                  </div>
                  <div className="md:w-1/2 mt-8 md:mt-0">
                    <div className="relative overflow-hidden rounded-3xl shadow-lg group">
                      <img 
                        src="images/family_reading.png"
                        alt="Family reading together" 
                        className="w-full transition-transform duration-300 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                      <div className="absolute bottom-0 left-0 right-0 p-4 text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                        <p className="text-lg font-semibold">Discover the magic of storytelling</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
  
            <Divider />
  
            {/* Features Section */}
            <section id="features" className="py-12 bg-white my-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="section-header mb-8 text-center">Features</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                  {[{
                    icon: <Book className="h-8 w-8 mx-auto" />, 
                    title: 'Personalized Stories', 
                    description: "Create stories that reflect your child's daily emotional experiences, integrating their unique interests and developmental needs."
                  }, {
                    icon: <Flower className="h-8 w-8 mx-auto" />, 
                    title: 'Mindfulness Techniques', 
                    description: 'Teach mindfulness through relaxation exercises like deep breathing, body scans, and emotional reflection within every story.'
                  }, {
                    icon: <Users className="h-8 w-8 mx-auto" />, 
                    title: 'Family-Friendly Content', 
                    description: 'Emotionally enriching stories designed to help children and families navigate feelings together, fostering connection and emotional growth.'
                  }, {
                    icon: <CheckCircle className="h-8 w-8 mx-auto" />, 
                    title: 'Easy to Use', 
                    description: 'Effortlessly create and customize stories in minutes, designed for busy parents to nurture emotional learning during bedtime.'
                  }].map((feature, index) => (
                    <motion.div
                      key={index}
                      className="bg-secondary p-6 rounded-lg shadow-lg text-center feature-card flex flex-col justify-between w-full"
                      custom={index}
                      initial="hidden"
                      animate="visible"
                      variants={featureVariants}
                    >
                      <div className="text-primary mb-4">{feature.icon}</div>
                      <h3 className="text-xl font-semibold mb-2 text-text">{feature.title}</h3>
                      <p className="text-text italic">{feature.description}</p>
                    </motion.div>
                  ))}
                </div>
              </div>
            </section>
  
            <Divider />
  
            {/* How It Works Section */}
            <section id="how-it-works" className="w-full py-16 bg-gradient-to-b from-purple-50 to-white border-2 border-purple-300 my-6">
              <div className="container px-4 md:px-6 mx-auto">
                <h2 className="section-header mb-16">How the Magic Happens</h2>
                <div className="relative">
                  <div className="absolute top-0 left-1/2 w-1 h-full bg-purple-200 transform -translate-x-1/2 rounded-full" /> {/* Keep the line visible */}
                  {[
                    {
                      icon: <BookOpen className="h-12 w-12 text-purple-500" />,
                      title: "Select Your Characters",
                      description: "Start by selecting your main character and companions for the story.",
                      bullets: [
                        "Choose a hero or adventurer.",
                        "Add companions for support or challenge.",
                        "Explore themes like empathy and teamwork."
                      ],
                      color: "bg-purple-100"
                    },
                    {
                      icon: <Wand2 className="h-12 w-12 text-blue-500" />,
                      title: "Set Story Details",
                      description: "Shape the story with custom settings, themes, and storytelling styles.",
                      bullets: [
                        "Pick magical settings like forests or kingdoms.",
                        "Choose emotional themes like kindness or bravery.",
                        "Personalize the story to reflect life lessons."
                      ],
                      color: "bg-blue-100"
                    },
                    {
                      icon: <Headphones className="h-12 w-12 text-green-500" />,
                      title: "Listen or Read",
                      description: "Bring the story to life with soothing narration or read together for family bonding.",
                      bullets: [
                        "Enjoy professional narration or read aloud.",
                        "Encourage mindfulness with calming audio.",
                        "Enhance emotional growth through storytelling."
                      ],
                      color: "bg-green-100"
                    },
                    {
                      icon: <Share2 className="h-12 w-12 text-pink-500" />,
                      title: "Share the Magic",
                      description: "Share your personalized stories with family and friends.",
                      bullets: [
                        "Share via text or audio.",
                        "Create memorable moments with loved ones.",
                        "Build a keepsake library of your child’s stories."
                      ],
                      color: "bg-pink-100"
                    }
                  ].map((step, index) => (
                    <motion.div
                      key={index}
                      className={`relative z-10 flex items-center mb-8 ${index % 2 === 0 ? 'justify-start pl-2' : 'justify-end pr-2'}`}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                      transition={{ duration: 0.8, delay: index * 0.2 }}
                      viewport={{ once: false, amount: 0.8 }} // Updated amount for earlier fade-out
                    >
                      <div className={`${step.color} p-6 rounded-lg shadow-lg max-w-md`}>
                        <div className="flex items-center mb-4">
                          <div className="mr-4 p-3 rounded-full bg-white">{step.icon}</div>
                          <h3 className="text-2xl font-semibold">{step.title}</h3>
                        </div>
                        <p className="text-gray-700">{step.description}</p>
                        <ul className="list-disc list-inside mt-4">
                          {step.bullets.map((bullet, idx) => (
                            <li key={idx} className="text-gray-700">{bullet}</li>
                          ))}
                        </ul>
                      </div>
                      <div 
                        className={`absolute top-1/2 ${index % 2 === 0 ? 'right-1/2' : 'left-1/2'} w-6 h-6 bg-purple-500 rounded-full transform -translate-y-1/2 ${index % 2 === 0 ? 'translate-x-1/2' : '-translate-x-1/2'} hidden md:block`} // Hide dots on mobile
                      />
                    </motion.div>
                  ))}
                </div>
                <div className="text-center mt-20">
                  <h3 className="text-3xl font-bold text-purple-700 mb-4">Your Magical Journey Awaits!</h3>
                  <p className="text-xl text-gray-600">Get ready to create unforgettable stories that will spark imagination and bring joy to children everywhere.</p>
                </div>
              </div>
            </section>
  
            <Divider />
  
            {/* Benefits Section */}
            <section id="benefits" className="py-12 bg-white mb-8 my-6" ref={benefitsRef}>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="section-header mb-12">Benefits</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                  {[{
                    icon: <Leaf className="h-8 w-8 mx-auto" />, 
                    title: 'Promotes Relaxation', 
                    description: 'Help your child unwind with calming stories that ease anxiety and promote restful sleep.'
                  }, {
                    icon: <Users className="h-8 w-8 mx-auto" />, 
                    title: 'Encourages Bonding', 
                    description: 'Create lasting memories through shared storytelling that strengthens family connections.'
                  }, {
                    icon: <Sprout className="h-8 w-8 mx-auto" />, 
                    title: 'Supports Emotional Growth', 
                    description: 'Nurture your child’s emotional intelligence with stories that teach empathy, self-awareness, and mindfulness.'
                  }, {
                    icon: <Heart className="h-8 w-8 mx-auto" />, 
                    title: 'Reduces Stress for Parents', 
                    description: 'Simplify bedtime routines and reduce stress by providing soothing, ready-to-use story experiences.'
                  }].map((benefit, index) => (
                    <motion.div
                      key={index}
                      className="bg-secondary p-6 rounded-lg shadow-lg text-center feature-card flex flex-col justify-between w-full"
                      custom={index}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={featureVariants}
                    >
                      <div className="text-primary mb-4">{benefit.icon}</div>
                      <h3 className="text-xl font-semibold mb-2 text-text">{benefit.title}</h3>
                      <p className="text-text italic">{benefit.description}</p>
                    </motion.div>
                  ))}
                </div>
              </div>
            </section>
  
            <Divider />
  
            {/* Testimonials Section */}
            <section className="py-12 bg-gradient-to-b from-purple-50 to-white border-t border-gray-200 relative overflow-hidden my-6">
              <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter" style={{background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"}}></div>
              </div>
              
              <div className="max-w-[90%] mx-auto px-4 sm:px-6 lg:px-8 relative">
                <h2 className="section-header mb-8">What Parents Are Saying</h2>
                <div className="relative">
                  <Slider ref={sliderRef} {...settings}>
                    {[{
                      name: 'Emily R.', 
                      role: 'Mother of two', 
                      quote: 'Make It Mindful has transformed our bedtime routine. My kids look forward to their nightly story!',
                      image: './images/testimonials/emily.png'
                    }, {
                      name: 'Michael S.', 
                      role: 'Father', 
                      quote: 'The personalized stories have helped my child deal with anxiety in a fun, engaging way.',
                      image: './images/testimonials/michael.png'
                    }, {
                      name: 'Sarah L.', 
                      role: 'Parent', 
                      quote: 'As a busy parent, I love how easy it is to create meaningful stories that my kids adore.',
                      image: './images/testimonials/sarah.png'
                    }, {
                      name: 'Jane K.', 
                      role: 'Mother of a toddler', 
                      quote: 'I love how Make It Mindful helps my daughter relax before sleep. The stories are perfect for her age.',
                      image: './images/testimonials/jane.png'
                    }, {
                      name: 'David M.', 
                      role: 'Father of three', 
                      quote: 'The stories are engaging and have become a favorite part of our family time.',
                      image: './images/testimonials/david.png'
                    }, {
                      name: 'Laura P.', 
                      role: 'Parent', 
                      quote: 'A wonderful way to bond with my children while teaching them mindfulness.',
                      image: './images/testimonials/laura.png'
                    }].map((testimonial, index) => (
                      <div key={index} className="px-2">
                        <div className="bg-white p-6 rounded-lg shadow-md border border-[#d1d1d1] w-full mx-auto flex flex-col items-center justify-between h-[300px]"> {/* Set a fixed height */}
                          <img className="h-16 w-16 rounded-full mb-4 object-cover" src={testimonial.image} alt={testimonial.name} />
                          <div className="flex-grow flex flex-col justify-between">
                            <p className="italic text-[#333] mb-4 text-center">{testimonial.quote}</p>
                            <div className="text-center">
                              <p className="font-semibold text-[#4d4d4d]">{testimonial.name}</p>
                              <p className="text-[#a6a6a6]">{testimonial.role}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <button 
                    onClick={goToPrev} 
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-[#e8f4fa] transition-colors duration-300 z-10"
                    style={{ left: "-20px" }}
                  >
                    <ChevronLeft className="h-6 w-6 text-[#6ca0dc]" />
                  </button>
                  <button 
                    onClick={goToNext} 
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-[#e8f4fa] transition-colors duration-300 z-10"
                  >
                    <ChevronRight className="h-6 w-6 text-[#6ca0dc]" />
                  </button>
                </div>
              </div>
            </section>
  
            <Divider />
  
            {/* Pricing and Upcoming Features Section */}
            <section id="pricing" className="py-12 bg-[#f7f7f7] my-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="section-header mb-4">Pricing and Plans</h2>
                <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
                  Choose the perfect plan to unlock the full potential of our mindful storytelling app.
                </p>
                
                <div className="flex justify-center items-center mb-8">
                  <span className={`mr-3 ${isYearly ? 'text-gray-500' : 'font-semibold'}`}>Monthly</span>
                  <Switch checked={isYearly} onCheckedChange={setIsYearly} />
                  <span className={`ml-3 ${isYearly ? 'font-semibold' : 'text-gray-500'}`}>Yearly</span>
                </div>

                <div className="px-0 md:px-[5%] lg:px-[10%]">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-4 justify-center">
                    {pricingPlans.map((plan, index) => (
                      <div key={index} className={`bg-white rounded-lg p-6 shadow-lg flex flex-col justify-between w-full max-w-sm mx-auto md:max-w-[300px]
                        ${index === 1 
                          ? 'border-2 border-blue-500 md:transform md:scale-105 shadow-xl' 
                          : 'border border-gray-200 hover:shadow-xl transition-shadow duration-300'
                        } shadow-blue-600/20`}>
                        {index === 1 && (
                          <div className="bg-blue-500 text-white text-xs font-semibold px-3 py-1 rounded-full inline-block mb-3 mx-auto absolute left-1/2 transform -translate-x-1/2 -top-3">
                            MOST POPULAR
                          </div>
                        )}
                        <div>
                          <h3 className="text-lg font-semibold mb-3 text-center">{plan.plan}</h3>
                          <div className="mb-3 text-center">
                            {isYearly && (
                              <span className="text-gray-500 line-through text-sm mr-1">
                                ${(plan.monthlyPrice * 12).toFixed(2)}
                              </span>
                            )}
                            <span className="text-2xl font-bold">
                              ${isYearly ? plan.yearlyPrice.toFixed(2) : plan.monthlyPrice.toFixed(2)}
                            </span>
                            <span className="text-gray-500 text-sm">/{isYearly ? 'Year' : 'Month'}</span>
                          </div>
                          {isYearly && (
                            <p className="text-xs text-blue-600 mb-3 text-center">
                              Save {calculateYearlyDiscount(plan.monthlyPrice, plan.yearlyPrice)}% with yearly billing
                            </p>
                          )}
                          <ul className="space-y-2 mb-4 text-sm">
                            {plan.features.map((feature, featureIndex) => (
                              <li key={featureIndex} className="flex items-center">
                                {typeof feature === 'string' ? (
                                  <>
                                    <svg className="w-4 h-4 mr-2 text-blue-500 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                    </svg>
                                    <span>{feature}</span>
                                  </>
                                ) : (
                                  <>
                                    <svg className="w-4 h-4 mr-2 text-red-500 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                    <span className="text-gray-500">{feature.text}</span>
                                  </>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <button className={`w-full py-2 px-4 rounded text-sm ${index === 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 border border-blue-500'} hover:bg-blue-600 hover:text-white transition-colors cursor-not-allowed opacity-75`} disabled>
                          Coming Soon
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Upcoming Features */}
                <div className="flex justify-center mt-12">
                  <div className="bg-gradient-to-r from-blue-100 via-green-100 to-purple-100 p-6 rounded-lg shadow-md text-center max-w-3xl">
                    <h3 className="section-header mb-2 text-primary">Upcoming Features</h3>
                    <p className="text-base text-text-muted">
                      We’re constantly working to improve your storytelling experience! Keep an eye out for exciting new features like <span className="font-bold italic">image generation</span>, <span className="font-bold italic">exclusive story templates</span>, <span className="font-bold italic">story sequels</span>, <span className="font-bold italic">and more</span> coming soon to Premium and Premium Plus plans!
                    </p>
                  </div>
                </div>
              </div>
            </section>
  
            <Divider />
  
            {/* FAQ Section */}
            <section id="faq" className="py-12 bg-gray-50 my-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="section-header mb-12">Frequently Asked Questions</h2>

                {/* Grid Layout with 3 Columns for 3 Categories */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                  
                  {/* Category 1: General Information */}
                  <div>
                    <h3 className="text-2xl font-bold mb-6">General Information</h3>
                    <Accordion type="single" collapsible className="space-y-4">
                      <AccordionItem value="faq-1" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          What ages is this app suitable for?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          Make It Mindful is designed for children aged 3-12, but the stories can be enjoyed by the whole family.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-2" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          Can I personalize the stories?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          Yes! You can add your child’s name, favorite activities, and other personal details to make each story unique.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-3" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          How do I create a story?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          Creating a story is simple! Just choose a theme, customize the details, and our AI will generate a unique, mindful story for you.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-4" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          Do I need an internet connection to use the app?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          Once a story is generated, it can be accessed offline. However, you will need an internet connection to initially create a story using our AI-driven tool.
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  {/* Category 2: Technical Support */}
                  <div>
                    <h3 className="text-2xl font-bold mb-6">Technical Support</h3>
                    <Accordion type="single" collapsible className="space-y-4">
                      <AccordionItem value="faq-5" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          I forgot my password. How can I reset it?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          You can reset your password by clicking the "Forgot Password" link on the login page and following the instructions sent to your email.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-6" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          Can I use Make It Mindful on multiple devices?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          Yes! You can access Make It Mindful on multiple devices. Just log in with your account credentials.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-7" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          How do I update my app to the latest version?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          You can update the app from your device's app store (Google Play for Android and the App Store for iOS). If you have automatic updates enabled, the app will update on its own.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-8" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          What should I do if the app crashes?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          If the app crashes, try restarting the app. If the issue persists, ensure you are using the latest version of the app and contact support if needed.
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  {/* Category 3: Subscription & Billing */}
                  <div>
                    <h3 className="text-2xl font-bold mb-6">Subscription & Billing</h3>
                    <Accordion type="single" collapsible className="space-y-4">
                      <AccordionItem value="faq-9" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          Is there a limit to the number of stories I can create?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          The app offers different subscription tiers. With the free version, you can create a limited number of stories per month. Our premium plans allow for unlimited story creation.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-10" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          Can I cancel my subscription?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          Yes, you can cancel your subscription anytime. Your access to premium features will continue until the end of the current billing cycle.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-11" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          How can I report a billing issue?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          If you encounter a billing issue, contact our support team at billing@makeitmindful.app, and we’ll resolve it as soon as possible.
                        </AccordionContent>
                      </AccordionItem>

                      <AccordionItem value="faq-12" className="border border-[#d1d1d1] rounded-lg">
                        <AccordionTrigger className="text-left px-4 py-2 hover:bg-[#e8f4fa] flex items-center justify-between">
                          Why can't I see my purchased features?
                          <ChevronDown className="h-5 w-5 text-[#6ca0dc]" />
                        </AccordionTrigger>
                        <AccordionContent className="px-4 py-2">
                          If you cannot see your purchased features, ensure you are logged in with the correct account. If the problem persists, try restoring your purchases from the app's settings.
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                  
                </div>
              </div>
            </section>
          </div>
        </main>

        {/* Footer - Updated form */}
        <footer className="bg-primary text-white py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div>
                <h3 className="text-lg font-semibold mb-4">Make It Mindful</h3>
                <p>Creating mindful stories for families.</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                <ul className="space-y-2">
                  <li><Link to="/terms" className="hover:underline">Terms of Service</Link></li>
                  <li><Link to="/privacy" className="hover:underline">Privacy Policy</Link></li>
                  <li><Link to="/contact" className="hover:underline">Contact Us</Link></li>
                </ul>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-4">Stay Connected</h3>
                <form onSubmit={handleSubmit} className="flex">
                  <Input 
                    type="email" 
                    placeholder="Enter your email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full rounded-l-full text-black bg-white" 
                    required
                  />
                  <Button type="submit" className="bg-primary hover:bg-primary-hover text-white rounded-r-full border-2 border-white">
                    Join Waitlist
                  </Button>
                </form>
                {message && <p className="mt-2 text-white">{message}</p>}
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-[#85d0a3] text-center">
              <p>&copy; 2024 Make It Mindful. All rights reserved.</p>
            </div>
          </div>
        </footer>
  
        {/* Email Capture Modal - Simplified for balanced spacing */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4" onClick={() => setIsModalOpen(false)}>
            <div className="bg-white rounded-lg w-full max-w-md mx-auto relative" onClick={(e) => e.stopPropagation()}>
              <button 
                onClick={() => setIsModalOpen(false)} 
                className="absolute top-4 right-4 text-[#a6a6a6] hover:text-[#4d4d4d]"
                aria-label="Close modal"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <div className="p-6 sm:p-8">
                <h2 className="text-xl sm:text-2xl font-bold mb-2 text-text-muted pr-8">Start Your Mindful Journey</h2>
                <p className="text-sm sm:text-base text-[#4d4d4d] mb-6">Enter your details to get started:</p>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <Input 
                    type="text" 
                    placeholder="Enter your name" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required 
                    className="w-full text-sm sm:text-base p-2 rounded-md border border-gray-300" 
                  />
                  <Input 
                    type="email" 
                    placeholder="Enter your email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required 
                    className="w-full text-sm sm:text-base p-2 rounded-md border border-gray-300" 
                  />
                  <Button type="submit" className="w-full bg-primary hover:bg-primary-hover text-white text-sm sm:text-base py-3 rounded-md">
                    Join Waitlist
                  </Button>
                </form>
                {message && <p className="mt-4 text-center text-sm sm:text-base text-[#4d4d4d]">{message}</p>}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default LandingPage;
